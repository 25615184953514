
import PNInformation from '@/components/smartPN/PNInformation.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Diagnostic from '@/components/smartPN/Diagnostic.vue'
import Summary from '@/components/smartPN/Summary.vue'
import DX from '@/components/smartPN/DX.vue'
import PdfMake from 'pdfmake'
import html2pdf from 'html2pdf.js'

import { PostSmartPN, postTempDX } from '@/components/admin/forms.service'
import {
  MedicalRecord,
  PaginationVM,
  PatientInfo,
  PostTempDX,
  ProgressNote,
  RecordDiagnostic,
  VisitOptions
} from '@/models'

// import { BvEvent } from 'bootstrap-vue'
enum Tabs {
  PNINFORMATION = 'pnInfo',
  DIAGNOSTIC = 'diagnostic',
  DX = 'DX',
  SUMMARY = 'summary'
}
import VueHtml2pdf from 'vue-html2pdf'
import {
  getPatientByRecord,
  getProgressNotes,
  getSelectedProgressNote
} from '@/components/patients/patients.service'
import moment from 'moment'

@Component({
  components: {
    PNInformation,
    Diagnostic,
    Summary,
    DX,
    VueHtml2pdf
  }
})
export default class SmartPN extends Vue {
  @Prop() public recordID!: string
  public perPage = 10
  public pageOptions = [10, 20, 50, 100, 250, 500, 1000]
  public pagination = {} as PaginationVM
  public patientInfo = {} as PatientInfo
  public fields = [
    {
      key: 'progressNoteID',
      label: 'Progress Note ID',
      tdClass: 'align-middle'
    },
    {
      key: 'recordID',
      label: 'Record ID',
      tdClass: 'align-middle',
      sortable: true
    },
    {
      key: 'progressNoteDate',
      formatter: 'formatDate',
      label: 'Date',
      tdClass: 'align-middle',
      sortable: true
    },
    {
      key: 'view',
      label: 'Actions'
    }
  ]
  public items: ProgressNote[] = []
  // async handleTabs(a, b, c) {
  //   // console.log(a, b, c)

  //   c.preventDefault()
  //   this.nextTab()
  // }
  // public submit() {
  //   const obj = this.extractMedicalRecord(this.form)
  //   console.log(obj)
  //   PostSmartPN(obj)
  //   if (this.currentTab < this.enabledTabs.length - 1) {
  //     this.$set(this.enabledTabs, this.currentTab + 1, true)
  //   }
  //   console.log(this.$refs)
  // }
  @Watch('perPage')
  perPageChange() {
    this.getData
  }
  public async onPageChange(pageNumber: number) {
    await this.getData(pageNumber, '')
  }
  get _pagination() {
    return this.pagination
  }
  public formatDate(value: string) {
    return moment(value).format('MM/DD/YYYY')
  }
  public goToSmartPN() {
    // this.PNInformation(this.pa,this.patientInfo.documentID)
    this.$router.push({ name: 'SmartPN', params: { recordID: this.recordID } })
  }

  public summary = null
  public password = null
  public data = null
  public progressNotes: ProgressNote[] = []

  public created() {
    this.getData()
  }

  getData(pageNumber = 1, searchQuery = '') {
    getPatientByRecord(this.recordID).then(r => {
      this.patientInfo = r[0]
      getProgressNotes(pageNumber, this.perPage, this.recordID).then(c => {
        this.progressNotes = c.data
        this.pagination = JSON.parse(c.headers['x-pagination'])
      })
    })
  }
  public async viewRecord(progressNoteID: number) {
    // console.log(progressNoteID)
    const result = await getSelectedProgressNote(progressNoteID, this.recordID)

    this.downloadPdf(result.pNblob, `SmartPN-${this.recordID}`)
    // const base64Blob = this.base64ToBlob(result,'application/pdf')
    // console.log(result)
  }

  downloadPdf(base64String, filename) {
    // Convert the Base64 string to a Blob
    const pdfBlob = this.base64ToBlob(base64String, 'application/pdf')

    // Create a URL for the Blob
    const pdfUrl = URL.createObjectURL(pdfBlob)

    // Create an anchor element and trigger the download
    const link = document.createElement('a')
    link.href = pdfUrl
    link.download = filename || 'document.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  base64ToBlob(base64Data, contentType) {
    // Decode the base64 string
    const byteCharacters = atob(base64Data)
    const byteNumbers = new Array(byteCharacters.length)

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: contentType })
  }

  handleCC(val) {
    // console.log(val)
    for (const header in val) {
      if (Object.prototype.hasOwnProperty.call(val, header)) {
        const element = val[header]
        // console.log(header)
        // console.log(element)
        for (const group in element) {
          if (Object.prototype.hasOwnProperty.call(element, group)) {
            const g = element[group]
            // console.log(
            //   g.filter(
            //     c =>
            //       (c.visitOptionDescription = this.processData(
            //         this.selectedItems[group],
            //         group
            //       ))
            //   )[0]
            // )
          }
        }
        // {{ processData(localSelected[group], group) }}

        // element.forEach(el => {
        //   console.log(el)
        // })
        // for (const k in element) {
        //   if (Object.prototype.hasOwnProperty.call(element, k)) {
        //     const el = element[key]
        //     console.log(el)
        //   }
        // }
      }
    }

    // console.log(this.selectedItems.filter(c=> ))
  }
  handleGroupedSummary(data) {
    // console.log(data)
    // console.log(data)
    // Object.keys(data).filter(c=> )
  }
  handleSummary(values) {
    // console.log(
    //   this.data
    //     .filter(c => values[c.visitGroupDescription])
    //     .filter(
    //       a => a.visitOptionID == values[a.visitGroupDescription].split('_')[0]
    //     )
    // )

    this.summary = values
  }
  handleMedication(value) {
    // console.log(value)
    // let result = null
    if (value != null) {
      const obj = {
        table: value
      }
      // console.log(value)
      this.form.jsonDRUGSTable = JSON.stringify(obj)
      // console.log(this.form.jsonDRUGSTable)
    } else {
      this.form.jsonDRUGSTable = null
    }
  }
  handleTime(value: VisitOptions[]) {
    this.timeOptions = value
  }
  processedName(name: string) {
    if (name) {
      const parts = name.split('_')
      return parts.length > 1 ? parts.slice(1).join('_') : name
    }
  }
  getGender(value: string) {
    return value == 'M' ? 'Male' : 'Female'
  }
  handleBilled(newSelected: RecordDiagnostic[]) {
    this.billed = newSelected
    const billedDX = this.billed.map(c => {
      return {
        DiagnosticID: c.diagnosticId,
        Descriptions: c.descriptions,
        Clinical_Manifestations: c.clinical_Manifestations,
        Treatment: c.treatment
      }
    })
    const obj = {
      table: billedDX
    }
    this.form.jsonDXTable = JSON.stringify(obj)
  }
  handleUpdatePnInfo(newSelected: Record<string, string>) {
    this.pnInfo = newSelected
    this.dataAvailable = true
    this.form.Specialty = newSelected.doctorInfo.speciality
  }
  handleUpdateSelected(newSelected: Record<string, string>) {
    this.selectedItems = newSelected

    this.form.VisitOptionID = this.processObjectToString(newSelected)
    this.form = {
      ...this.form,
      ...this.changeKeys(newSelected, this.replaceSpacesWithUnderscore)
    }
    this.form['AGEOFMENARCHE'] = this.form['AGE_OF_MENARCHE']
    this.form['IntensityPAINSCALE'] = this.form['INTENSITY_PAIN_SCALE']
    this.form['OTHERASSESSMENTNOTE'] = this.form['OTHER_ASSESSMENT_NOTE_']
    this.form['CSECTION'] = this.form['C-SECTION']
  }
  handleTreatment(newSelected: RecordDiagnostic) {
    const billedDX = this.billed
      .filter(c => c.diagnosticId == newSelected.diagnosticId)
      .map(c => {
        return {
          DiagnosticID: c.diagnosticId,
          Descriptions: c.descriptions,
          Clinical_Manifestations: c.clinical_Manifestations,
          Treatment: c.treatment
        }
      })
    const obj = {
      table: billedDX
    }
    this.form.jsonDXTable = JSON.stringify(obj)
  }
  handleClinical(newSelected: RecordDiagnostic) {
    const billedDX = this.billed
      .filter(c => c.diagnosticId == newSelected.diagnosticId)
      .map(c => {
        return {
          DiagnosticID: c.diagnosticId,
          Descriptions: c.descriptions,
          Clinical_Manifestations: c.clinical_Manifestations,
          Treatment: c.treatment
        }
      })
    const obj = {
      table: billedDX
    }
    this.form.jsonDXTable = JSON.stringify(obj)
  }
  handleUpdateOtherValues(newOtherValues: Record<string, string>) {
    this.otherValues = newOtherValues
    const obj = this.changeKeys(this.otherValues, this.prefixKey)

    this.form = { ...this.form, ...obj }
  }
  replaceSpacesWithUnderscore(key) {
    return key.replace(/[\s/]+/g, '_').toUpperCase()
  }
  prefixKey(key) {
    return `Other${this.replaceSpacesWithUnderscore(key)}`
  }
  changeKeys(obj, transformKey) {
    const newObj = {}
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const newKey = transformKey(key)
        newObj[newKey] = obj[key]
      }
    }
    return newObj
  }

  handleUpdateCpt(newVal: any) {
    this.selectedCPT = newVal
    if (newVal == 660) {
      this.form.VisitOptionID = '660'
    } else if (typeof newVal == 'string') {
      this.form.OtherCPT = newVal
    } else {
      this.form.VisitOptionID = newVal.visitOptionID.toString()
      this.form['CPT'] = newVal.visitOptionID.toString()

      this.form.OtherCPT = null
    }
    // console.log(newVal)
  }
  handleUpdateServiceDate(newVal: string) {
    this.serviceDate = newVal
    this.form.SERVICEDATE = new Date(newVal)
  }
  handleDiagnostics(newVal) {
    this.diagnostics = newVal.map(c => c.diagnosticId).join()
  }
  handleData(val) {
    // console.log(val.filter(c => this.selectedItems[c.visitOptionDescription]))

    this.data = val
  }

  public selected = {}
  public tab = Tabs
  public currentTab = 0
  private tabArray = [Tabs.PNINFORMATION, Tabs.DIAGNOSTIC, Tabs.SUMMARY]
  get tabIndexes() {
    return this.tabArray.map((_, index) => index)
  }
  get selectedData() {
    return this.selectedItems
  }
  @Watch('enabledTabs', { deep: true, immediate: true })
  onEnabledTabsChange(newVal: boolean[]) {
    // console.log(11)
    if (newVal[this.currentTab + 1]) {
      this.$nextTick(() => {
        this.currentTab += 1
      })
    }
  }
  public async nextTab() {
    const stepIsValid = await this.validateChild()
    // console.log(stepIsValid)
    if (!stepIsValid) {
      this.scrollToFirstError()
      return
    }
    this.scrollToTop()
    if (this.currentTab == 1) {
      const obj = {
        visitOptionID: this.processObjectToString(this.selectedItems),
        recordID: this.recordID,
        diagnostics: this.diagnostics,
        serviceDate: this.serviceDate,
        specialty: this.pnInfo?.doctorInfo?.speciality
      } as PostTempDX

      await postTempDX(obj)
    }
    if (this.currentTab < this.enabledTabs.length - 1) {
      // this.enabledTabs[this.currentTab + 1] = true
      this.$set(this.enabledTabs, this.currentTab + 1, true)
      // this.currentTab++
      // Explicitly set currentTab after enabling the next tab
      // this.currentTab = this.currentTab + 1
    }

    // if (this.currentTab < this.enabledTabs.length - 1) {
    //   this.enabledTabs[this.currentTab + 1] = true
    //   this.$nextTick(() => {
    //     this.currentTab++
    //   })
    // }
  }

  public scrollToFirstError() {
    const firstErrorElement = this.$el.querySelector('.is-invalid')
    // console.log(firstErrorElement)
    if (firstErrorElement) {
      firstErrorElement.scrollIntoView({ behavior: 'smooth' })
    }
  }
  public scrollToTop() {
    const firstErrorElement = this.$el.querySelector('.card-header')
    // console.log(firstErrorElement)
    if (firstErrorElement) {
      firstErrorElement.scrollIntoView({ behavior: 'smooth' })
    }
  }
  public prevTab() {
    this.currentTab--
  }
  get isSelectedItemsNotEmpty() {
    return Object.keys(this.selectedItems).length > 0
  }
  public async validateChild() {
    const childObserver = this.$refs[this.tabArray[this.currentTab]] as any
    let validation: boolean
    // if(childObserver)
    if (childObserver && childObserver.$refs.observer) {
      const response = await childObserver.$refs.observer.validate()
      validation = response
    } else {
      // this.currentTab++
      validation = true
    }
    return validation
  }
  public processObjectToString(obj) {
    const values = []

    Object.values(obj).forEach(value => {
      if (typeof value === 'string') {
        // If the value is a string, extract part before the first underscore or whitespace
        values.push(value.split(/[_\s]/)[0])
      } else if (Array.isArray(value)) {
        // If the value is an array of strings, extract part before the first underscore or whitespace for each element
        value.forEach(item => values.push(item.split(/[_\s]/)[0]))
      }
    })

    // Join all values into a single comma-separated string
    return Array.from(new Set(values.filter(val => !isNaN(val))))
      .sort()
      .join(', ')
  }
}
